.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #2a2a2a;
}

.App-link {
  color: #61dafb;
}

/*LOGIN*/
.cont_general {
  height: 100vh;
  overflow-y: auto;
}
.contImgLogin {
  background-repeat: no-repeat;
  background-size: cover;
}
.imgLogin {
  max-width: 100%;
  display: block;
  margin: 0 auto;
}
.login_contenedor {
  max-width: 550px;
  margin: 0 auto;
  padding-top: 20vh;
}
button.btn_login_relleno {
  color: #fff;
  background-color: #1976d2;
}
button.btn_login_relleno:hover {
  color: #fff;
  background-color: #0c60b4;
}
button.btn_cancelar_relleno {
  color: #fff;
  background-color: #e00202;
}
button.btn_cancelar_relleno:hover {
  color: #fff;
  background-color: #bd0b0b;
}
button.btn_login_relleno_blanco {
  color: #1976d2;
  background-color: #fff;
  border-color: #1976d2;
  border-style: solid;
  border-width: 1px;
  box-shadow: none;
}
button.btn_login_relleno_blanco:hover {
  color: #1976d2;
  background-color: rgb(235, 235, 235);
  box-shadow: none;
}
button.btn_login_texto {
  color: #1976d2;
}
.iconoLogin {
  color: #4a4b4c;
}
.iconoOjo{
  color: #757575;
}
/*FIN LOGIN*/

/*MENSAJES*/
.altoMensajes {
  height: calc(100vh - 193px);
}
.altoMensajes .btn_redactar {
  font-size: 15px;
  padding: 6px 10px;
}
.lista_mensajes .listaMensajes {
  padding: 8px 20px;
}
.lista_mensajes .certificados_texto span {
  cursor: pointer;
  display: inline-block;
}
#modal_conversacion .contenidoConver {
  border-bottom: none;
}
.mensaje {
  width: 49%;
  font-size: 14px;
}
.mensaje.mi_mensaje {
  border-top-left-radius: 0px;
  float: left;
  clear: both;
}
.mensaje.user_mensaje {
  float: right;
  clear: both;
}
.mensaje .tituloMensaje {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
  display: block;
}
.mensaje .textoMensaje {
  margin-bottom: 15px;
  border-radius: 20px;
  padding: 15px 20px 15px 15px;
  line-height: 21px;
}
.mensaje .textoMensaje p {
  margin: 0px;
}
.ck-editor__editable_inline {
  min-height: 150px;
}
.textNoti p{margin: 0px;}
.inpFile.MuiInput-underline:before {border:none}
.inpFile.MuiInput-underline:hover:not(.Mui-disabled):before{border:none}
.inpFile.MuiInput-underline:after {border:none}
.mensaje .fechaMensaje {
  margin-bottom: 5px;
  font-size: 13px;
}
.mensaje.mi_mensaje .textoMensaje {
  border-top-left-radius: 0px;
  background-color: #dde5ec;
}
.mensaje.user_mensaje .textoMensaje {
  border-top-right-radius: 0px;
  background-color: #1976d2;
  color: #fff;
}
.mensaje.user_mensaje .fechaMensaje {
  text-align: right;
}
.lista_mensajes li {
  padding-left: 0px;
  padding-right: 0px;
}
.lista_mensajes .botones_certificados {
  width: 265px !important;
  text-align: right;
}
.lista_mensajes .certificados_texto {
  width: calc(100% - 329px);
}
/*FIN MENSAJEs*/

/*PERFIL*/
.lista_certificados li {
  padding-left: 0px;
  padding-right: 0px;
}
.lista_certificados .botones_certificados {
  width: 265px !important;
  text-align: right;
}
.lista_certificados .certificados_texto {
  width: calc(100% - 329px);
}
/*FIN PERFIL*/
/*CONTACTO*/
.modal_contacto {background-color: #fff; padding: 10px 20px 20px; width: 600px; border-radius: 5px;}
/*FIN CONTACTO*/
/*CABECERA CUESTIONARIOS*/
.cabeceraCursos {display: block !important; height: auto !important;}
.cabeceraCursos .divCabCursos {display: inline-block; width: 33%;}
.cabeceraCursos .divCabCursos > div {display: inline-block !important; vertical-align: middle;}
.cabeceraCursos .divCabCursos .divisorCabecera {height: 20px !important;}
.cabeceraCursos .divCabCursos .tituloCurso {width: calc(100% - 135px);}
/*.cabeceraCursos .divCabCursos .cabeceraBotones {width: 231px; text-align: right;}*/
.cabeceraCursos .divCabCursos .cabeceraBotones {text-align: right;}
.cabeceraCursos .divCabCursos .cabeceraBotones > button {display: inline-block;}
.cabeceraCursos .divCabCursos .cabeceraBotones > button svg {vertical-align: middle;}
.cabeceraCursos .divCabCursos .cabeceraBotones > div {display: inline-block;}
.cabeceraCursos .divCabCursos.lineaBotones {margin-bottom: 10px;padding-left: 5px;text-align: right;}
/*FIN CABECERA CUESTIONARIOS*/
/*CUESTIONARIOS*/
.miCuerpo {overflow-y: auto; max-height: 100%; min-height: 100%;}
.miCuerpo .sv-title.sv-container-modern__title {
  display: none;
}
.miCuerpo .sv-body {width: 720px !important; margin: 0 auto !important;}
.miCuerpo .sv-body > div {margin-left: 0px !important; margin-right: 0px !important;}
.miCuerpo .miBoton.sv-footer__next-btn {background-color: #1976d2 !important;}
.miCuerpo .miBoton {border-radius: 5px;
  padding: 6px 15px;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 500;}
.miCuerpo .miBoton.sv-footer__prev-btn {border: 1px solid #1976d2;}
.miCuerpo .miBoton.sv-footer__complete-btn {background-color: #1976d2 !important;}
.miCuerpo .sv-question__header {margin-bottom: 25px;}
.miCuerpo .sv-question__title {
  font-family: "Roboto";
  font-weight: 600;
  font-size: 16px;
}
.miCuerpo .sv-question__title--answer {
  background-color: #fff;
}
.miCuerpo .sv-radio--checked .sv-radio__svg {
  border-color: #1976d2;
  fill: #1976d2;
}
.miCuerpo .sv-checkbox--checked .sv-checkbox__svg {
  border-color: #1976d2;
  background-color: #1976d2;
}
.miCuerpo .sv-text:focus {
  border-color: #1976d2;
  outline: none;
  box-shadow: none;
}
.miCuerpo .sv-comment:focus {
  border-color: #1976d2;
}
.miCuerpo .sv-progress {background-color: #fff; text-align: center; margin-bottom: 0px; margin-top: 60px !important;}
.miCuerpo .sv-progress__bar {display: none;}
.miCuerpo .sv-progress__text {
  position: unset;
  margin: 0;
  padding: 0;
  color: #1a1a1a;
  font-size: 15px;
  font-weight: 500;
}
.miCuerpo .sv-footer {
  margin-top: 0px;
  padding-top: 0px;}

.miCuerpo .sv-body.sv-completedpage::before{
  display: none;
}
.miCuerpo .sv-body.sv-completedpage > h4 {
  font-family: 'Roboto';
  font-size: 24px;
  font-weight: 200;
}
.miCuerpo .sv-body.sv-completedpage > h4 > b {
  font-weight: 200 !important;
}
  .contentIncidencia {max-width: 100%; margin: 0 auto;}
  .notasPersonales {max-width: 700px; margin: 0 auto;}
/*FIN CUESTIONARIOS*/
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.paginaIni {
  min-height: calc(100vh - 210px);
}
.footer{
  background-color:#f4f4f4;
  height: 160px;
  padding:25px;
  text-align:center;
}

.header{padding:12px 25px 0px;height:61px;}

.letras{background:orange!important;display:inline-flex!important;margin-left:25px;}
.letras_off{display: none !important;}
.avatar_perfil{vertical-align: middle;}

.copyRight{font-size:13px; color:#888888;margin-top:25px!important;}



.titleCurso{text-align:left;font-size:30px;}

.Nav{background-color:#505763!important;color:white!important;}
.NavContainer{width: 960px; margin: 0 auto;}

.tabsC.Mui-selected{color:white!important;border-bottom: 5px solid white; padding-bottom: 1px;}
.tabsC{color:white!important;}

.Cardd {width:230px!important;display:inline-block!important;margin: 10px 5px 10px 5px;text-align:left;}


.CartasContainer{text-align:left;}
.CartasContainer > div {
  padding: 24px 0px;
}
.CartasDiv {width: 960px; margin: 0 auto;}
.CartasContainer > .CartasDiv > div {padding: 24px 0px;}


.spanCard{font-size:11px!important;}
.cardAction{display:inline!important;text-align:center;}
.titleCard{font-size:13px;font-weight:bold;font-family: 'Roboto'!important;height:25px; color: #29303b}
.cardText{font-size:13px;}
.imgContainer{height:130px;width:100%;}

.form-control{padding:9px;}
#searchFor{width:200px;}
.buscador input{height:38px!important;}
.buscadorContainer{
  -webkit-box-shadow: 0px 6px 11px -1px rgba(148,145,148,0.43);
  -moz-box-shadow: 0px 6px 11px -1px rgba(148,145,148,0.43);
  box-shadow: 0px 6px 11px -1px rgba(148,145,148,0.43);
  background-color:#fbfbf8;
}
.buscadorDiv {width: 960px; margin: 0 auto; padding: 15px 0px;}

.mItem{border-top: 1px solid #dcdacb!important;padding: 15px!important;}
.cc:hover{color:#0f7c90;}
.inBusc .MuiOutlinedInput-input{padding: 9.5px 14px!important;}
.c737373{color:#737373!important;margin-right:5px;}
.Smensajes {margin-top:0px!important;}

.tabFormacion{color:'#f7f8fa';font-family:'Roboto';}
.VtabName.Mui-selected{color:#3c3b37!important;border-bottom:2px solid #3c3b37}

.pdfPage canvas{margin:auto!important;}

.video-js{width:100%!important;}
.pdfCurso{overflow: auto;height:630px;}

.quizCurso, .preLoadVideoCurso{height:527px;}
.video-js{height:auto;}

.contenidosCurso{
  padding:10px;
  border-bottom:1px solid #DEDFE0;
  cursor:pointer;
}

.MuiTab-root{text-transform:inherit!important;}

.contenidosCurso:hover{
  background:#f2f3f5;
}

.activeIcon svg{color:#1976d2;}
.activeDiv{
padding:10px;
border-bottom:1px solid #DEDFE0;
cursor:pointer;  
background-color:#e6f2f5;
}

.topVistaLastOptions .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded{top:50px!important}

.ql-picker-label{text-align:left!important;}

.aeb4bf{color:#aeb4bf}
.inline{display:inline-block;}
.fs12{font-size:12px!important;}
.fs15{font-size:15px!important;}
.avfs{width:56px!important;height:56px!important;font-size:30px!important;}
.fright{float:right;}
.fleft{float:left;}
.bold{font-weight:bold!important;}
.w20{width:20px;}
.ql-snow .ql-editor pre.ql-syntax {
  padding: 2px 4px!important;
  font-size: 90%!important;
  color: #B35A1B!important;
  background-color: #FFF!important;
  border: 1px solid #DEDFE0!important;
  border-radius: 4px!important;
}


.MuiCircularProgress-colorPrimary {color:#1976d2!important;}
.temasMenu:focus {
  border: 1px solid #5f5f5f!important;
}
.contenidoMenu:focus {
  border: 1px solid #5f5f5f!important;
}
.contenidoMenu:focus-visible {
  outline: none!important;
}
.temasMenu .MuiAccordionSummary-content{display:inline!important;}

div.MuiCardContent-root{padding:0px!important}

div.MuiCardContent-root p{padding:16px!important}

@media (max-width: 1010px) {
  .NavContainer{width: 750px; margin: 0 auto;}
  .buscadorDiv {width: 750px; margin: 0 auto;}
  .CartasDiv {width: 760px; margin: 0 auto;}
  .Cardd {margin: 10px 13px 10px 10px;}
}

@media (max-width: 959px) {
  .altoMensajes {
    height: auto;
  }
}

@media (max-width: 835px) {
  .cabeceraCursos .divCabCursos .divProgreso {margin: 0px 5px 0px 0px !important;}
}

@media (max-width: 780px) {
  .NavContainer{width: auto; margin: unset; padding: 0px 15px;}
  .buscadorDiv {width: auto; margin: unset; padding: 15px;}
  .buscadorDiv .fleft, .buscadorDiv .fright {float: none; display: block;}
  .buscadorDiv .fleft {margin-bottom: 10px;}
  #searchFor {width: 310px;}
  .buscadorDiv .fright > div {width: 255px;}
  .CartasDiv {width: auto;margin: unset;padding: 0px 20px;text-align: center;}
  .Cardd {margin: 10px 15px;}

  .lista_certificados li {
    display: block;
  }
  .lista_certificados li .listaAvatar {
    display: inline-block;
  }
  .lista_certificados .botones_certificados {
    width: auto !important;
    text-align: left;
    padding-left: 65px;
  }
  .lista_certificados .botones_certificados button {
    margin: 5px 15px 5px 0px !important;
  }
  .lista_certificados .certificados_texto {
    width: calc(100% - 66px);
    display: inline-block;
    vertical-align: top;
  }

  .miCuerpo .sv-body {
    width: 98% !important;
    margin: 0 auto !important;
  }

  .mensaje {
    width: 70%;
  }

  /*Cabecera cursos*/
  .cabeceraCursos {display: block !important; height: auto !important;}
  .cabeceraCursos .divCabCursos {display: block; width: 100%;}
  .cabeceraCursos .divCabCursos > div {display: inline-block !important; vertical-align: middle;}
  .cabeceraCursos .divCabCursos .divisorCabecera {height: 20px !important;}
  .cabeceraCursos .divCabCursos .tituloCurso {width: calc(100% - 135px);}
  .cabeceraCursos .divCabCursos .cabeceraBotones {width: calc(100% - 170px); text-align: right;}
  .cabeceraCursos .divCabCursos .cabeceraBotones > button {display: inline-block;}
  .cabeceraCursos .divCabCursos .cabeceraBotones > button svg {vertical-align: middle;}
  .cabeceraCursos .divCabCursos .cabeceraBotones > div {display: inline-block;}
  .cabeceraCursos .divCabCursos.lineaBotones {margin-bottom: 10px;padding-left: 0px; text-align: center;}
}

@media only screen and (max-width:700px){
    div.header{height:auto;}
    div.header > div {width: 100%; margin: 0px;}
    div.header div.MuiGrid-root div.MuiGrid-root div.fleft{width:100%;text-align:center;}
    div.header div.MuiGrid-root div.MuiGrid-root div.fright{width:100%;text-align:center;margin-top:15px;}
}

@media (max-width: 660px) {
  .modal_contacto {width: 85vw;}
}


#fixedContainer{
  overflow-y: auto;
    position: fixed;
    width:25%;
}
@media only screen and (max-width:960px){
  #fixedContainer{
      width:100%;
      position:initial;
      overflow-y:unset;
  }
}